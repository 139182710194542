import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import { Card, Container } from 'react-bootstrap'
import SocialMedia from "../components/social-medias"
import Img from "gatsby-image"

import Image from '../components/image';
// function ActualityPage({ data }) {
//   const sources = [
//     data.mediaqueri.Media.childImageSharp.fluid,
//     {
//       ...data.desktop.Media.childImageSharp.fluid,
//       media: `(min-width: 768px)`,
//     },
//   ]
//   return (
//     <Layout>
//       <SEO title="Actuality" />

//       <div className={"Actuality-Header"}>
//         <Img
//           style={{
//             width: `100%`,
//             position: "absolute",
//             minHeight: "100%"
//             // maxWidth: 1200,
//             // padding: `0 1.0875rem 1.45rem`,
//           }}
//           fluid={sources} alt={data.strapiActualityBanner.MediaTitle} />
//         <div className={"text"}>
//           <h1>News</h1>
//           <p>Find here all the latest news on our product</p>
//         </div>
//       </div>
//       <Container className={"actuality-container"}>

//         {data.allStrapiActuality.nodes.map((node, index) => (
//           <Card key={index} style={{ width: '90%' }}>
//             <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} />
//             <Card.Body>
//               <Card.Title>{node.Title}</Card.Title>
//               <Card.Text>
//                 {node.Text}
//               </Card.Text>
//             </Card.Body>
//           </Card>


//         ))
//         }
//       </Container>

//       <div className={"banner-more"}>
//         <div className={"banner-more-content"}>
//           <h2>
//             Want more?
//                     </h2>
//           <p>
//             Contact us by mail or find us on our social media.
//                     </p>

//           <SocialMedia height={"50px"} />
//         </div></div>
//     </Layout >
//   )
// }
function ActualityPage({}) {
  // const sources = [
  //   data.mediaqueri.Media.childImageSharp.fluid,
  //   {
  //     ...data.desktop.Media.childImageSharp.fluid,
  //     media: `(min-width: 768px)`,
  //   },
  // ]
  return (
    <Layout>
      <SEO title="Actuality" />

      <div className={"Actuality-Header"}>
      <Image
                src="news.jpg"
                className="mx-auto shadow-xl"
                alt="Gamotion matress image"
                style={{
                    width: `100%`,
                    position: "absolute",

                    minHeight: "100%"
                    // maxWidth: 1200,
                    // padding: `0 1.0875rem 1.45rem`,
                }}
            /> 
        {/* <Img
          style={{
            width: `100%`,
            position: "absolute",
            minHeight: "100%"
            // maxWidth: 1200,
            // padding: `0 1.0875rem 1.45rem`,
          }}
          fluid={sources} alt={data.strapiActualityBanner.MediaTitle} /> */}
        <div className={"text"}>
          <h1>News</h1>
          <p>Find here all the latest news on our product</p>
        </div>
      </div>
      <Container className={"actuality-container"}>

        {/* {data.allStrapiActuality.nodes.map((node, index) => (
          <Card key={index} style={{ width: '90%' }}>
            <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} />
            <Card.Body>
              <Card.Title>{node.Title}</Card.Title>
              <Card.Text>
                {node.Text}
              </Card.Text>
            </Card.Body>
          </Card>


        ))
        } */}

<Card key={1} style={{ width: '90%' }}>
            {/* <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} /> */}
            
            <Image
                                    src="salonsoins.jpg"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: "600",
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
            <Card.Body>
              <Card.Title>Salon Soins et Santé (September 30 - October 1th 2021)</Card.Title>
              <Card.Text>
              During this medical congress, you will have the opportunity to discover and to test our product. Meet us there!
              </Card.Text>
            </Card.Body>
          </Card>
      <Card key={2} style={{ width: '90%' }}>
            {/* <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} /> */}
            <Image
                                    src="wcol.jpg"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: "600",
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                /> 
            
            <Card.Body>
              <Card.Title>WCO-IOF-ESCEO London 2021 (August 26 - 29th 2021)</Card.Title>
              <Card.Text>
              During this medical congress, you will have the opportunity to discover and to test our product. Meet us there!
              </Card.Text>
            </Card.Body>
          </Card>
      <Card key={3} style={{ width: '90%' }}>
            {/* <Img variant="top" fluid={node.Media.childImageSharp.fluid} alt={node.MediaTitle} /> */}
            <Image
                                    src="wcov.jpg"
                                    className="mx-auto shadow-xl"
                                    alt="Gamotion matress image"
                                    style={{
                                        width: "600",
                                        minHeight: "100%",
                                        maxWidth: "1200",
                                        // padding: `0 1.0875rem 1.45rem`,,
                                    }}
                                />
            <Card.Body>
              <Card.Title>WCO-IOF-ESCEO virtual congress (August 20 - 22nd 2020)</Card.Title>
              <Card.Text>
              We are proud to present our brand new product at the World's leading clinical conference on bone, joint, and muscle health where an exhibition area is dedicated to the GAMotion boarding game.
              </Card.Text>
            </Card.Body>
          </Card>
      
      </Container>

      <div className={"banner-more"}>
        <div className={"banner-more-content"}>
          <h2>
            Want more?
                    </h2>
          <p>
            Contact us by mail or find us on our social media.
                    </p>

          <SocialMedia height={"50px"} />
        </div></div>
    </Layout >
  )
}

// export const query = graphql`
// query {

//   strapiActualityBanner {
//     MediaTitle
//   }
//     desktop: strapiActualityBanner {
      
//       Media {
//           childImageSharp {
//             fluid(quality: 100) {
//               ...GatsbyImageSharpFluid
//                  }
//             }
//         } 
//     }  
//   mediaqueri: strapiActualityBanner {
   
//       Media {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//                  }
//             }
//         } 
//     }


//     allStrapiActuality {
//     nodes {
//       Date
//       Title
//       Text
//       children {
//         id
//       }
//       Media {
//           childImageSharp {
//             fluid(quality: 100) {
//               ...GatsbyImageSharpFluid
//                  }
//             }
//         } 
//     }
//   }

  
// }
// `
export default ActualityPage